import Store from './store';
import { makeAutoObservable } from 'mobx';
import { ProfileService } from '../services/profile.service';
import { RolesEnum, TeacherTitleEnum } from '../common/enums';
import { GetProfileLeaderboard, AccountTeacher, TicketType } from '../models/types';
import { AxiosResponse } from 'axios';


export default class ProfileStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  async acceptTerms(): Promise<void> {
    try {
      await ProfileService.acceptTerms();
    } catch (e) {
      throw e;
    }
  }

  async getTerms(): Promise<{ data: { id: number, terms: any, date_created: Date } }> {
    try {
      return await ProfileService.getTerms();
    } catch (e) {
      throw e;
    }
  }

  async getProfileLeaderboard(limit?: number): Promise<{ data: GetProfileLeaderboard }> {
    try {
      return await ProfileService.getProfileLeaderboard(limit);
    } catch (e) {
      throw e;
    }
  }

  async updatePerson(values: {
    role: RolesEnum,
    first_name?: string,
    middle_name?: string,
    last_name?: string,
    title_id?: TeacherTitleEnum
  }): Promise<{ data: AccountTeacher }> {
    try {
      return await ProfileService.updatePerson(values);
    } catch (e) {
      throw e;
    }
  }

  async updatePassword(data: {
    password: string,
  }): Promise<void> {
    try {
      return await ProfileService.updatePassword(data);
    } catch (e) {
      throw e;
    }
  }

  async updateImage(data: {
    account_img: string,
  }): Promise<void> {
    try {
      return await ProfileService.updateImage(data);
    } catch (e) {
      throw e;
    }
  }

  async updateEmailRequest(data: {
    email_address: string,
    isMobile: boolean
  }): Promise<AxiosResponse> {
    try {
      return await ProfileService.updateEmailRequest(data);
    } catch (e) {
      throw e;
    }
  }

  async updateEmail(key: string, token: string, email: string): Promise<{ data: boolean }> {
    try {
      return await ProfileService.updateEmail(key, token, email);
    } catch (e) {
      throw e;
    }
  }

  async uploadAvatar(formData: FormData): Promise<{ data: string }> {
    try {
      return await ProfileService.uploadAvatar(formData);
    } catch (e) {
      throw e;
    }
  }

  async getTicketTypes(): Promise<{ data: TicketType[] }> {
    try {
      return await ProfileService.getTicketTypes();
    } catch (e) {
      throw e;
    }
  }
}