import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL || '/api',
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, OPTIONS'
  },
  credentials: 'include'
});

$api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.data.statusCode === 401) {
      const { message } = error.response.data;
      if (message === 'Invalid or expired token') {
        window.location.href = '/login?expired=true';
      } else if (message === 'No token provided') {
        window.location.href = '/login';
      }

      return await Promise.reject(error);
    } else {
      return error.response;
    }
  }
);

export default $api;
