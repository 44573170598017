// Core
import React, { FC, Suspense, lazy, useEffect, useState, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

// Components
import AuthenticatedLayout from '../../components/authenticated-layout/authenticated-layout';
import TermsOfServicePopup from '../../components/terms-of-service-popup/terms-of-service-popup';

// Other
import { Context } from '../../index';

// Types
import ThirdLogin from '../../pages/third-login/third-login';
import ProtectedRoute from './protected-route';
import { RolesEnum } from '../../common/enums';
import { Account } from '../../models/types';

// Lazy
const Login = lazy(() => import('../../pages/login/login'));
const MainPage = lazy(() => import('../../pages/main/main'));
const StudentsPage = lazy(() => import('../../pages/students/students'));
const StudentPage = lazy(() => import('../../pages/student/student'));
const StudentInsightsPage = lazy(() => import('../../pages/student/insights/insights'));
const InsightsPage = lazy(() => import('../../pages/insights/insights'));
const InsightsDetailsPage = lazy(() => import('../../pages/insights-details/insights-details'));
const AccountPage = lazy(() => import('../../pages/account/account'));
const ResetPasswordPage = lazy(() => import('../../pages/reset-password/reset-password'));
const NewEmailSuccessPage = lazy(() => import('../../pages/new-email/new-email'));

const unauthorizedPages = ['/login', '/new-email', '/reset-password'];

const Root: FC = () => {
  const location = useLocation();

  const {
    store: {
      authStore: { checkAuth, isAuth, setAuth, setAccount, account }
    }
  } = useContext(Context);

  const [isTermsAgree, setTermsAgree] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (unauthorizedPages.every(page => !location.pathname.includes(page))) {
      (async () => {
        setLoading(true);
        const user = await checkAuth();
        if (user) {
          setAccount(user);
          setAuth(true);
          setTermsAgree(user.agreed_to_terms);
        } else {
          setAuth(false);
        }
        setLoading(false);
      })();
    } else {
      setLoading(false);
      setAuth(false);
    }
  }, []);

  if (!isTermsAgree) {
    return (
      <TermsOfServicePopup setTermsAgree={setTermsAgree} />
    );
  }

  return (
    <AuthenticatedLayout>
      <Suspense fallback={<div />}>
        {!loading && (
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute isAuth={isAuth} account={account!}
                                roles={[RolesEnum.teacher]}>
                  <MainPage account={account! as Account} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/students"
              element={
                <ProtectedRoute isAuth={isAuth} account={account!}
                                roles={[RolesEnum.teacher]}>
                  <StudentsPage account={account! as Account} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/:id"
              element={
                <ProtectedRoute isAuth={isAuth} account={account!}
                                roles={[RolesEnum.teacher]}>
                  <StudentPage account={account!} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student/:id/insights/:type"
              element={
                <ProtectedRoute isAuth={isAuth} account={account!}
                                roles={[RolesEnum.teacher]}>
                  <StudentInsightsPage account={account!} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/insights"
              element={
                <ProtectedRoute isAuth={isAuth} account={account!}
                                roles={[RolesEnum.teacher]}>
                  <InsightsPage account={account!} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/insights/:type"
              element={
                <ProtectedRoute isAuth={isAuth} account={account!}
                                roles={[RolesEnum.teacher]}>
                  <InsightsDetailsPage account={account!} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/insights/:type/:class_id"
              element={
                <ProtectedRoute isAuth={isAuth} account={account!}
                                roles={[RolesEnum.teacher]}>
                  <InsightsDetailsPage account={account!} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute isAuth={isAuth} account={account!}
                                roles={[RolesEnum.parent, RolesEnum.teacher, RolesEnum.student]}>
                  <AccountPage account={account!} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/new-email"
              element={<NewEmailSuccessPage account={account!} />}
            />
            <Route
              path="/login"
              element={isAuth ? <Navigate to="/" /> : <Login />}
            />
            <Route path="/login/:service" element={isAuth ? <Navigate to="/" /> : <ThirdLogin />} />
            <Route
              path="/reset-password"
              element={isAuth ? <Navigate to="/" /> : <ResetPasswordPage />}
            />
          </Routes>
        )}
      </Suspense>
    </AuthenticatedLayout>
  );
};

export default observer(Root);
